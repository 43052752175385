export function easeInOutQuad(currentTime, start, change, duration) {
  currentTime /= duration / 2
  if (currentTime < 1) {
    return change / 2 * currentTime * currentTime + start
  }
  currentTime--
  return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
}

export function easeInCubic(currentTime, start, change, duration) {
  const timeChangeCubic = (currentTime /= duration) * currentTime * currentTime
  return start + change * timeChangeCubic
}

export function inOutQuintic(currentTime, start, change, duration) {
  const ts = (currentTime /= duration) * currentTime
  const tc = ts * currentTime
  return start + change * (6 * tc * ts + -15 * ts * ts + 10 * tc)
}
