import React, { Component } from 'react'
import { FaChevronDown } from 'react-icons/fa'

import otm from '../images/OTM.png'
import ttf from '../images/ttf.png'
import bltm from '../images/bltm.jpeg'
import tnd from '../images/tnd.png'
import tndbg from '../images/TNDbg.jpg'
import DetailsSection from '../components/DetailsSection'
import TimeLine from '../components/TimeLine'
import TheyTrustUs from '../components/TheyTrustUs'
import Footer from '../components/Footer'
import Layout from '../layouts'
import Scroller from '../components/Scroll'
import { withScroll } from '../components/Scroll'
import flight from '../images/flight.png'
import HomeSection from '../components/HomeSection'

export class Travel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      whiteLogo: this.updateHeaderColor(props.activeSection),
      activeColor: this.getActiveColor(props.activeSection),
    }
  }
  componentDidMount() {
    switch (window.location.hash) {
      case '#outbound-travel-mart':
        this.props.goToSection(1)
        break
      case '#travel-tourism-fair':
        this.props.goToSection(2)
        break
      case '#business-luxury-travel-mart':
        this.props.goToSection(3)
        break
      case '#timeline1':
        this.props.goToSection(4)
        break
      case '#timeline01':
        this.props.goToSection(5)
        break
      case '#travel-news-digest':
        this.props.goToSection(6)
        break
      default:
        this.props.goToSection(0)
        break
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { whiteLogo, activeColor: activeColorState } = this.state
    if (nextProps.activeSection !== this.props.activeSection) {
      const white = this.updateHeaderColor(nextProps.activeSection)
      if (white !== whiteLogo) {
        this.setHeaderColor(white)
      }
      const activeColor = this.getActiveColor(nextProps.activeSection)
      if (activeColor !== activeColorState) {
        this.setActiveColor(activeColor)
      }
    }
  }
  componentDidUpdate() {
    const { activeSection, scrolling } = this.props
    if (!scrolling) {
      switch (window.location.hash) {
        case '#outbound-travel-mart':
          if (activeSection !== 1) this.props.goToSection(1)
          break
        case '#travel-tourism-fair':
          if (activeSection !== 2) this.props.goToSection(2)
          break
        case '#business-luxury-travel-mart':
          if (activeSection !== 3) this.props.goToSection(3)
          break
        case '#timeline1':
          if (activeSection !== 4) this.props.goToSection(4)
          break
        case '#timeline01':
          if (activeSection !== 5) this.props.goToSection(5)
          break
        case '#travel-news-digest':
          if (activeSection !== 6) this.props.goToSection(6)
          break
        default:
          if (activeSection !== 0) this.props.goToSection(0)
          break
      }
    }
  }
  setHeaderColor = whiteLogo => this.setState({ whiteLogo })
  updateHeaderColor = active => {
    switch (active) {
      case 0:
        return true
      case 1:
        return false
      case 2:
        return false
      case 3:
        return false
      case 4:
        return false
      case 5:
        return false
      case 6:
        return false
      default:
        return false
    }
  }
  getActiveColor = active => {
    switch (active) {
      case 1:
        return '#d1234d'
      case 2:
        return '#004a82'
      case 3:
        return '#5f9e43'
      case 4:
        return '#4d4d4d'
      case 5:
        return '#4d4d4d'
      case 6:
        return '#da2128'
      default:
        return 'grey'
    }
  }

  getInActiveColor = active => {
    switch (active) {
      case 1:
        return 'grey'
      case 2:
        return 'grey'
      case 3:
        return 'grey'
      case 4:
        return 'grey'
      case 5:
        return 'grey'
      case 6:
        return 'grey'
      default:
        return 'grey'
    }
  }
  setActiveColor = activeColor => this.setState({ activeColor })
  render() {
    const { whiteLogo, activeColor: activeColorState } = this.state
    const { activeSection } = this.props
    return (
      <Layout whiteLogo={whiteLogo}>
        {activeSection !== 0 && (
          <Scroller.DotGroup
            className="dots"
            renderDot={({ active, index, ...props }) => {
              return (
                <button
                  className="dot"
                  {...props}
                  style={{
                    backgroundColor:
                      index === active
                        ? activeColorState
                        : this.getInActiveColor(active),
                  }}
                />
              )
            }}
          />
        )}
        {/* {activeSection !== 0 && (
          <Scroller.NextButton className="half-circle">
            <div
              className="bottom-arrow"
              style={{ backgroundColor: activeColorState }}
            >
              <i>
                <FaChevronDown color="#fff" />
              </i>
            </div>
          </Scroller.NextButton>
        )} */}
        <Scroller.Sections>
          <Scroller.Section>
            <HomeSection
              id="travel-and-tourism"
              Heading="TRAVEL &amp; TOURISM"
              links={[
                {
                  to: '/travel-and-tourism#outbound-travel-mart',
                  name: 'TRADESHOWS',
                },
                {
                  to: '/travel-and-tourism#travel-news-digest',
                  name: 'PUBLICATIONS',
                },
              ]}
              links1={[
                { to: 'travel-and-tourism#outbound-travel-mart', name: 'OTM' },
                { to: 'travel-and-tourism#travel-tourism-fair', name: 'TTF' },
                {
                  to: 'travel-and-tourism#business-luxury-travel-mart',
                  name: 'BLTM',
                },
                { to: 'travel-and-tourism#travel-news-digest', name: 'TND' },
              ]}
              button={[
                { to: 'travel-and-tourism#timeline1', name: 'View Schedule' },
              ]}
              img={flight}
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="outbound-travel-mart"
              src={otm}
              buttonText="Visit Website"
              buttonLink="https://otm.co.in/"
              stats={[
                { number: '1600+', text: 'Exhibitors' },
                { number: '600+', text: 'Hosted Buyers' },
                { number: '40,000+', text: 'Trade Visitors' },
              ]}
              youtubeurl="https://www.youtube.com/embed/9_kKGlspAcI?si=UCLR81SHB7lmXu7x"
              headerText="OTM is the gateway to India's largest travel markets. It stands as the largest and most internationally diverse gathering of travel trade buyers and professionals in India. It is held every year in Mumbai, the largest source market of outbound travel in the country. OTM was certified as the “Leading Travel Trade Show in India and Asia”, in a customised study by NielsenIQ — the world’s leading consumer intelligence company, commissioned by Fairfest Media. The research, conducted among travel industry professionals who attended multiple trade shows in the region, identified OTM as the #1 travel trade show in India and Asia, significantly outperforming other major shows."
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="travel-tourism-fair"
              src={ttf}
              buttonText="Visit Website"
              buttonLink="https://www.ttfotm.com/"
              stats={[
                { number: '4000+', text: 'Exhibitors' },
                { number: '100+', text: 'Countries' },
                { number: '60,000+', text: 'Trade Visitors' },
              ]}
              youtubeurl="https://www.youtube.com/embed/zJuEbZa7tJ4?si=ACAr99Ew7P4iQe4z"
              headerText="TTF is India's oldest and largest travel show network. Started in 1989, TTF covers 8 top domestic and outbound tourism markets of India – Kolkata, Ahmedabad, Delhi, Hyderabad, Patna, Mumbai, Bengaluru, and Chennai. Every year, more than 4,000 exhibitors from 100 countries and 36 Indian states and Union Territories take part in TTF - the best place for networking with the Indian travel trade."
            />
          </Scroller.Section>

          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="urban-news-digest"
              src={bltm}
              buttonText="Visit Website"
              buttonLink="https://bltm.co.in/"
              stats={[
                { number: '600+', text: 'Exhibitors' },
                { number: '500+', text: 'Hosted Buyers' },
                { number: '10,000+', text: 'Trade Visitors' },
              ]}
              youtubeurl="https://www.youtube.com/embed/y-pZx1UveCI?si=1jMNY3IEVD1qTCYu"
              headerText="BLTM is India's leading trade show on Business + Leisure Travel and MICE. BLTM stands as a unique platform for both business and leisure destinations to effectively target the rapidly expanding corporate and MICE travel segments in India. BLTM hosts over 500 top buyers from India, Asia, the Americas, Europe and other prominent travel markets. BLTM also boasts an attendance of over 10,000 pre-qualified travel trade professionals. The event enables pre-scheduled appointments via a state-of-the-art online tool."
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <TimeLine />
          </Scroller.Section>

          <Scroller.Section className="mobile-default-scroll">
            <TheyTrustUs />
          </Scroller.Section>

          <Scroller.Section className="mobile-default-scroll pb-0">
            <DetailsSection
              id="travel-news-digest"
              src={tnd}
              imgurl={tndbg}
              buttonText="Visit Website"
              buttonLink="http://www.travelnewsdigest.in/"
              headerText="As innumerable travel trade news products overcrowd the market, it is essential to offer something unique to the vibrant travel trade fraternity. Travel News Digest aggregates, analyses and features news from leading global sources in a crisp format, making it an essential resource for time-constrained travel professionals."
            />
            <Footer id="tnd" />
          </Scroller.Section>
        </Scroller.Sections>
      </Layout>
    )
  }
}
export default withScroll({ sections: 7 })(Travel)
