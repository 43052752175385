import React, { Component, createContext, createRef } from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { easeInOutQuad } from './utils'

const ScrollerContext = createContext({ scrollBy: 0 })

class Scroller extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    sections: PropTypes.number.isRequired,
  }

  static Sections = ({ children, ...props }) => (
    <div {...props} style={{ transition: 'all 203ms ease-in-out' }}>
      {children}
    </div>
  )
        
  static Section = ({ children, ...props }) => (
    <div
      {...props}
      style={{ height: '100vh', width: '100%', position: 'relative' }}
    >
      {children}
    </div>
  )

  static NextButton = ({ children, ...props }) => (
    <ScrollerContext.Consumer>
      {({ shouldRenderNextButton, goToNextSection }) =>
        shouldRenderNextButton() ? (
          <button {...props} onClick={goToNextSection}>
            {children}
          </button>
        ) : null
      }
    </ScrollerContext.Consumer>
  )

  static Dot = ({ children, ...props }) => (
    <button {...props}>{children}</button>
  )

  static DotGroup = ({ renderDot, ...props }) => (
    <ScrollerContext.Consumer>
      {({ sections, goToSection, activeSection }) => {
        const dots = []
        for (let index = 0; index < sections; index++) {
          const onClick = event => {
            event.preventDefault()
            event.stopPropagation()
            // goToSection(index)
          }
          dots.push(
            renderDot ? (
              renderDot({ index, onClick, active: activeSection })
            ) : (
              <Scroller.Dot key={index} index={index} onClick={onClick} />
            )
          )
        }
        return <div {...props}>{dots}</div>
      }}
    </ScrollerContext.Consumer>
  )

  state = {
    scrollBy: 0,
    autoScroll: true,
    activeSection: 0,
  }

  componentDidMount() {
    window.addEventListener('wheel', this.onWheel, { passive: true })
    window.addEventListener('keydown', this.onKeyDown, { passive: true })
    window.addEventListener('mouseup', this.onScrollUpClick, {
      passive: true,
    })
    window.addEventListener('mousedown', this.onScrollClick, {
      passive: true,
    })
    window.addEventListener('scroll', this.handleScroll, {
      passive: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.onWheel)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('mouseup', this.onScrollUpClick)
    window.removeEventListener('mousedown', this.onScrollClick)
    window.removeEventListener('scroll', this.handleScroll)
  }

  scrolling = false

  resetScrolling = () => {
    this.scrolling = false
  }

  debounceResetScroll = debounce(this.resetScrolling, 450, {
    trailing: true,
    leading: false,
    maxWait: 100,
  })

  onWheel = e => {
    if (!this.scrolling) {
      this.scrolling = true
      if (e.deltaY > 0) {
        e.preventDefault()
        this.scrollUpwards()
      } else {
        e.preventDefault()
        this.scrollDownwards()
      }
    }
  }

  onKeyDown = e => {
    if (!this.scrolling && e.buttons === 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        this.scrolling = true
        this.scrollUpwards()
        this.debounceResetScroll()
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        this.scrolling = true
        this.scrollDownwards()
        this.debounceResetScroll()
      }
    }
  }

  onScrollUpClick = e => {
    e.preventDefault()
    if (!this.scrolling && e.buttons === 0) {
      this.scrolling = true
      e.clientY < window.innerHeight / 2
        ? this.scrollDownwards()
        : this.scrollUpwards()
      this.debounceResetScroll()
    }
  }

  onScrollClick = e => {
    e.preventDefault()
    if (!this.scrolling && e.buttons === 0) {
      this.scrolling = true
      e.clientY < window.innerHeight / 2
        ? this.scrollDownwards()
        : this.scrollUpwards()
      this.debounceResetScroll()
    }
  }

  handleScroll = e => {
    e.preventDefault()
    this.scrolling = true
    const currentScroll = window.scrollY
    const sectionHeight = window.innerHeight
    const activeSection = Math.floor(currentScroll / sectionHeight)
    this.setState({ activeSection })
  }

  scrollTo = (to, callback, duration = 1000) => {
    // Increased duration for slower scrolling
    const start = document.documentElement.scrollTop || document.body.scrollTop
    const change = to - start
    const increment = 20
    let currentTime = 0

    const animateScroll = () => {
      currentTime += increment
      const val = easeInOutQuad(currentTime, start, change, duration)
      document.documentElement.scrollTop = val
      document.body.scrollTop = val
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll)
      } else if (callback) {
        callback()
      }
    }

    animateScroll()
  }

  scrollToSection = section => {
    const targetScrollPosition = section * window.innerHeight
    this.setState(
      { activeSection: section, scrollPosition: targetScrollPosition },
      () => {
        this.scrollTo(targetScrollPosition, this.resetScrolling, 0)
      }
    )
  }

  scrollUpwards = () => {
    this.setState(
      ({ activeSection }) => {
        const newSection = Math.min(activeSection + 1, this.props.sections - 1)
        return { activeSection: newSection }
      },
      () => {
        this.scrollToSection(this.state.activeSection)
      }
    )
  }

  scrollDownwards = () => {
    this.setState(
      ({ activeSection }) => {
        const newSection = Math.max(activeSection - 1, 0)
        return { activeSection: newSection }
      },
      () => {
        this.scrollToSection(this.state.activeSection)
      }
    )
  }

  shouldRenderNextButton = () => {
    const { activeSection } = this.state
    const { sections } = this.props
    return activeSection < sections - 1
  }

  render() {
    const { scrollBy, activeSection } = this.state
    const { sections, children } = this.props

    return (
      <div style={{ height: '100vh', width: '100%' }} onWheel={this.onWheel}>
        <ScrollerContext.Provider
          value={{
            scrollBy,
            sections,
            activeSection,
            scrolling: this.scrolling,
            shouldRenderNextButton: this.shouldRenderNextButton,
            goToNextSection: this.scrollUpwards,
            goToSection: this.scrollToSection,
          }}
        >
          {children}
        </ScrollerContext.Provider>
      </div>
    )
  }
}

export const withScroll = scrollerProps => WrappedComp => {
  return class WithScrollComponent extends Component {
    render() {
      return (
        <Scroller {...scrollerProps}>
          <ScrollerContext.Consumer>
            {props => <WrappedComp {...props} />}
          </ScrollerContext.Consumer>
        </Scroller>
      )
    }
  }
}

export default Scroller
