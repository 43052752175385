import React from 'react'
// import circle from '../../images/circle.png'
import trave1 from '../../images/LOGO/trave1.jpg'
import trave2 from '../../images/LOGO/trave2.jpg'
import trave3 from '../../images/LOGO/trave3.jpg'
import turkey from '../../images/LOGO/turkey.png'
import greece from '../../images/LOGO/greece.png'
import egypt from '../../images/LOGO/egypt.png'
import indo from '../../images/LOGO/indo.png'
import china from '../../images/LOGO/china.png'
import thai from '../../images/LOGO/thai.png'
import nepal from '../../images/LOGO/nepal.png'
import rwanda from '../../images/LOGO/rwanda.png'
import kenya from '../../images/LOGO/Kenya-logo.png'
import maharastra from '../../images/LOGO/Maharashtra-logo.png'
import kores from '../../images/LOGO/korea-logo.png'
import goa from '../../images/LOGO/goa-logo.png'
import enjoycity from '../../images/LOGO/enjoy-logo.png'
import taiwan from '../../images/LOGO/Taiwan.png'
import indialogo from '../../images/LOGO/India-logo.png'
import gujarat from '../../images/LOGO/Gujarat-logo.png'
import bahrain from '../../images/LOGO/Bahrain-logo.png'
import airindia from '../../images/LOGO/AirIndia-logo.png'
import cox from '../../images/LOGO/cox-logo.png'
import westbangal from '../../images/LOGO/westbengal-logo.png'
import srilanka from '../../images/LOGO/Srilanka-logo.png'

import malaysai from '../../images/LOGO/Malaysia-logo.png'
import usa from '../../images/LOGO/Brand-USA-logo-Discover-America.png'
import bang from '../../images/LOGO/Bangladesh-logo.png'
// import atoai from '../../images/LOGO/Atoai-logo.png'
// import adtoai from '../../images/LOGO/Adtoi-logo.png'
import bhutan from '../../images/LOGO/Bhutan-logo.png'
import taj from '../../images/LOGO/taj.png'
import newZealand from '../../images/LOGO/new-zealand.png'
import tbo from '../../images/LOGO/tbo.png'
import otoai from '../../images/LOGO/otoai.png'
import cambodia from '../../images/LOGO/Cambodia-logo.png'
import maldives from '../../images/LOGO/Maldives-logo.png'
import hplogo from '../../images/LOGO/HP-logo.png'

// import andrapradesh from '../../images/LOGO/Andhra-Pradesh.png'
// import kenya from '../../images/LOGO/Kenya.png'
// import Uttarakhand from '../../images/LOGO/Uttarakhand.png'
// import Telangana from '../../images/LOGO/Telangana.png'

// import circle from '../../images/circle.png'

import './TimeLine.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const TimeLine = ({ id, stats, buttonName }) => (
  <section id="timeline1 they-trust-us-timeline">
    <div className="otm-home1">
      <div className="event-time">
        <div className="col-sm-12">
          <p className="pag-line-trust">THEY TRUST US</p>
        </div>

        <Slider {...settings}>
          <div>
            <img alt="" className="img-fluid partner-img" src={trave1} />
          </div>
          <div>
            <img alt="" className="img-fluid partner-img" src={trave2} />
          </div>
          <div>
            <img alt="" className="img-fluid partner-img" src={trave3} />
          </div>
          {/* <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        </Slider>
      </div>
    </div>
  </section>
)

export default TimeLine
