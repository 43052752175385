import React from 'react'
import {  FaLinkedin } from 'react-icons/fa'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import styled from 'styled-components'
import './Footer.css'
import { Link } from 'gatsby'

const SocialLink = styled.a`
  border: solid 2px #ffffff;
  color: #fff;
  padding: 4px;
  width: 29px;
  text-decoration: none;
  margin: 0px 10px 0px 0px;
  height: 30px;
  display: inline-block;

  &:hover,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
`

const Footer = ({ id }) => (
  <div className="row footer">
    <div className="tbottm col-sm-8">
      <p className="vbottm term-condi">&copy; 2024 Fairfest Media Ltd. | <Link to="/privacy-policy" target='_blank' >Privacy Policy</Link> | <Link to="/terms-and-conditions" target='_blank'>Terms and Conditions</Link> | <Link to="/refund_policy" target='_blank'>Refund Policy</Link></p>
    </div>
    <div className="tbottm col-sm-4 text-right">
    <SocialLink
        href="https://www.facebook.com/fairfestmedialtd?mibextid=LQQJ4d"
        target="_blank"
        rel="noopener noreferrer"
        title="Facebook"
        aria-label="Facebook page of fairfest media"
      >
        <FaFacebookSquare />
      </SocialLink>
 
      <SocialLink
        href="https://www.linkedin.com/company/fairfest-media-limited/"
        target="_blank"
        rel="noopener noreferrer"
        title="linkedin"
        aria-label="linkedin page of fairfest media"
      >
        <FaLinkedin />
      </SocialLink>
    </div>
    
  </div>
)

export default Footer
