import React from 'react'
import {Link} from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'
import './HomeSection.css'

const DetailsSection = ({ id, Heading, img, links, links1, button }) => (
  <div>
    <section id={id}>
      <div className="Home-section">
        <div className="home-content">
          <div className="heding">{Heading}</div>
          {links &&
            links.map((link, index) => (
              <div className="Home-inner-text">
                <Link to={link.to}> {link.name} </Link>
                <span>{index !== links.length - 1 && ' |'}&nbsp;</span>
              </div>
            ))}
        </div>
        <div className="section-name">
          {links1 &&
            links1.map((link, index) => (
              <div className="txtinline">
                <Link to={link.to}> {link.name}</Link>
                <span>{index !== links1.length - 1 && ' |'}&nbsp;</span>
              </div>
            ))}
          {button &&
            button.map(link => (
              <div className="view-siduleeee">
                <Link to={link.to} className="ViewSchedule-btn">
                  {link.name}
                  <i>
                    <FaChevronRight />
                  </i>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div className="tt-img-div">
        <img alt="" className="tt-img" src={img} />
      </div>
    </section>
  </div>
)

export default DetailsSection
