import React, { Component, Fragment } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './DetailsSection.css'

class DetailsSection extends Component {
  state = { videoOpen: false }
  handleCloseModal = () => this.setState({ videoOpen: false })
  handleOpenModal = () => this.setState({ videoOpen: true })
  render() {
    const {
      id,
      headerText,
      src,
      customImage,
      imgurl,
      stats = [],
      youtubeurl,
      videoUrl,
      buttonLink,
      buttonText = 'Visit Website',
      isButtonShow = true
    } = this.props
    const { videoOpen } = this.state
    const Tag = buttonLink ? 'a' : 'button'
    const tagProps = buttonLink
      ? { href: buttonLink, target: '_blank' }
      : { onClick: this.handleOpenModal }

    return (
      <section id={id}>
        <div className="otm-home">
          <div className="row align-items-start">
            <div
              style={youtubeurl ? { height: '350px' } : {}}
              className="col-md-6 col-sm-12"
            >
              {youtubeurl && (
                <iframe
                  style={{ width: '100%', height: '100%' }}
                  src={youtubeurl}
                  title="OTM - At a glance"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              )}
              <img src={imgurl} className="img-fluid" />
            </div>

            <div className="col-md-6 col-sm-12">
             {customImage?customImage: <img className="img-fluid img-logo" src={src} alt={id} />}
              <div className="OTM-text mb-3">{headerText}</div>
              {stats.length ? (
                <div className="row mb-3">
                  {stats.map(({ number, text }) => (
                    <div key={number + text} className="col-sm-4">
                      <div className="otm-num">{number}</div>
                      <div className="otm-exh-name">{text}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              {isButtonShow && <Tag {...tagProps} className="WatchVideo-btn">
                <div className="video">
                  {buttonText}
                  <i>
                    <FaChevronRight />{' '}
                  </i>
                </div>
              </Tag>}
              {!buttonLink && (
                <VideoModal
                  url={videoUrl}
                  open={videoOpen}
                  handleClose={this.handleCloseModal}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

class VideoModal extends Component {
  render() {
    const { url, handleClose, open } = this.props
    return (
      <Fragment>
        {open && (
          <Fragment>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: 'rgba(0,0,0,0.2)',
              }}
              onClick={handleClose}
            />
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <iframe
                width="840"
                height="473"
                src={`https://www.youtube.com/embed/${url}?rel=0&amp;showinfo=0`}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="video"
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
export default DetailsSection
